<template>
<!--  style="background-color: #42b983"-->
<div>
<message-s2 :c1="c1"  @out="out" v-if="messageShow" >

</message-s2>

  <div class="ss1">
    <div class="t2"><span>姓名:<input type="text" v-model="from.name"></span></div>
    <div class="t2"><span>手机号码:<input type="text" v-model="from.mobile"></span></div>
    <div class="t2">
      <span>审核状态:
        <select v-model="from.audit_status">
          <option value="待审核" >待审核</option>
          <option value="已通过">已通过</option>
          <option value="未通过">未通过</option>
        </select>
      </span>
    </div>
    <div ><input type="button" value="搜索" style="width: 70%" @click="souSuo()"></div>
  </div>
  <div class="ss2">
    <div><input type="button" value="添加" style="width: 70%"></div>
    <div><input type="button" value="导出" style="width: 70%" @click="excl"></div>
  </div>

<!--  background-color: #42b983-->
  <div style="width: 100% ;margin-top:20px; background-color: white" class="table1">
    <table>
      <thead class="">
        <th><input type="checkbox" v-model="chcekAll"></th>
        <th>姓名</th>
        <th>手机号</th>
        <th>年龄</th>
        <th>服务类型</th>
        <th>服务内容</th>
        <th>审核状态</th>
        <th>操作</th>
      </thead>
      <tbody>
      <tr v-for="(item,index) of data1" :key="index">
        <td><input type="checkbox" v-model="item.checked"></td>
        <td>{{item.name}}</td>
        <td>{{item.mobile}}</td>
        <td>{{item.age}}</td>
        <td>{{item.source}}</td>
        <td>{{item.source}}</td>
        <td>{{item.audit_status}}</td>
        <td class="ss4">
          <span class="ss5" style="cursor: pointer" @click="xiangXi(item)">详细</span>
          <span class="ss5" style="cursor: pointer" @click="bianyi(item)">编辑</span>
          <span class="ss5" style="cursor: pointer" @click="tonguo(item)">通过</span>
          <span class="ss5" style="cursor: pointer" @click="jujue(item)">拒绝</span>
        </td>
      </tr>


      </tbody>

    </table>
  </div>

</div>

</template>

<script>
import axios from "axios";
import MessageS2 from "@/components/MessageS2";
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx'
import Utio from "@/components/utio/Utio";
// npm install  xlsx
export default {

  name: "Index1S2",
  components: {MessageS2},
  data(){
    return {
      from:{
        name:"",
        mobile:"",
        audit_status:"待审核",

      },
      data1:[],
      c1:{
        s1:false,  //设置是查看还是选择
        data:{},
      },
      // messageShow:false
      messageShow:false,
      chcekAll:false,
    }
  },
  methods:{
    excl(){

      let ex=[];
      if(this.chcekAll==true){ //判断是否选择全部
        ex=this.data1;
      }else{
        for(let k of this.data1){  //找选择的
          if(k.checked==true){
            ex.push(k)
          }
        }
      }




// 将 JavaScript 数组转换为 Excel 数据格式
      const worksheet = XLSX.utils.json_to_sheet(ex);

// 创建一个空的 workbook 对象
      const workbook = XLSX.utils.book_new();
// 将 worksheet 添加到 workbook 中
      XLSX.utils.book_append_sheet(workbook, worksheet, 'data');

// 导出 workbook 到 Excel 文件
      XLSX.writeFile(workbook, 'data.xlsx');


    },

    out(){
      console.log("床底")
      this.messageShow=false;
    },
    //搜索
   async souSuo(){
      console.log("开始")




      let {data}= await axios.get(Utio.ip()+"volunteer.from",{
        params:this.from,
        // headers:{
        //   "Content-Type":"application/json"
        // },

      })
      for(let d1 of data){
        d1.checked=false;
      }
      this.data1=data;
      console.log("查询到的值:")
      console.log(data)
    },

    //详细
    xiangXi(item){
     this.c1.data=item
     this.c1.s1=false
      this.messageShow=true;

    },
    bianyi(item){
      this.c1.data=item
      this.c1.s1=true
      this.messageShow=true;

    },
     async tonguo(item) {
     let {data}=   await axios.get(Utio.ip()+`volunteer.update?id=${item.id}&audit_status=已通过`, {
         params: {}
       })

       if(data==true){
         item.audit_status="已通过"
       }


       // console.log(data)


     },
    async jujue(item) {

      let {data} = await axios.get(Utio.ip()+`volunteer.update?id=${item.id}&audit_status=未通过`, {
        params: {}
      })


      if(data==true){
        item.audit_status="未通过"
      }


      // console.log(data)


    }
  },
  async created() {

    console.log("请求")
    let {data}= await axios.get(Utio.ip()+"volunteer",{
        param:null,
        // headers:{
        //   "Content-Type":"application/json"
        // },

      })
    for(let d1 of data){
      d1.checked=false;
    }
    this.data1=data;
    // console.log(data)


  }
}
</script>

<style scoped>
.ss5:hover{
  color: red;
}


th, td {
  padding: 40px; /* 设置单元格内边距 */
  text-align: left; /* 文字左对齐 */
  border: 1px solid #000; /* 设置单元格边框 */
}
.table1{
  height: 62vh; /* 设置表格容器的固定高度 */
  overflow: auto; /* 滚轮*/
}





.ss1{
  border-bottom: 3px black ridge;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1vh; /**控制间距*/
  /*grid-row-gap: 100px;*/
}

.ss4{

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2vh; /**控制间距*/
  /*grid-row-gap: 100px;*/
}


.ss2{
  display: grid;
  grid-template-columns: 1fr 1fr 10fr ;
  grid-column-gap: 1vh; /**控制间距*/
  /*grid-row-gap: 100px;*/
}

.t2{
  display: flex;
  /*background-color: red;*/
}


</style>