<template>
<div>
    <div class="tt1">
          <div class="tt2">
                <div class="ss3" style="margin-top: 100px">
<!--                  {{messageData}}-->
                  <div>姓名:</div>
                  <div><input type="text" v-model="messageData.name"  maxlength="10"></div>
                  <div ></div>
                  <div>性别: </div>
                  <div><span><input type="checkbox" v-model="checkedBol.nan">男<input type="checkbox" v-model="checkedBol.nv">女</span></div>


                  <div>民族:</div>
                  <div><input type="text"  v-model="messageData.nation" maxlength="5"></div>
                  <div></div>
                  <div>年龄:</div>
                  <div><input type="number" v-model="messageData.age" max="999"></div>



                  <div>身份证:</div>
                  <div><input type="text" v-model="messageData.id_card"></div>
                  <div></div>
                  <div>手机号码:</div>
                  <div><input type="text" v-model="messageData.mobile"></div>



                  <div>家庭住址:</div>
                  <div><input type="text" v-model="messageData.address"></div>
                  <div/>
                  <div/>
                  <div/>



                  <div>服务类型:</div>
                  <div>
                    <select v-model="messageData.source">
                       <option>招募网站</option>
                    </select>

                  </div>
                  <div></div>
                  <div>服务内容:</div>
                  <div>
                    <select v-model="messageData.source">
                      <option>招募网站</option>
                    </select>
                  </div>


                  <div>服务时段:</div>
                  <div>
                    <select>
                      <option value="01:00">09:00</option>
                      <option value="02:00">09:00</option>
                      <option value="03:00">09:00</option>
                      <option value="04:00">09:00</option>
                      <option value="05:00">09:00</option>
                      <option value="06:00">09:00</option>
                      <option value="07:00">09:00</option>
                      <option value="08:00">09:00</option>
                      <option value="09:00">09:00</option>
                    </select>
                  </div>
                  <div/>
                  <div/>
                  <div/>



                  <div>身份证正面:</div>
                  <div><img :src="messageData.id_card_font_image" style="width: 100px;height: 70px;border: black 1px ridge"></div>
                  <div></div>
                  <div>身份证反面:</div>
                  <div> <div><img :src="messageData.id_card_back_image" style="width: 100px;height: 70px;border: black 1px ridge"></div></div>




                  <div></div>
                  <div></div>
                  <div>
                    <input type="button" value="提交" v-if="c1.s1==true" @click="update()">
                    <input type="button" value="退出" @click="out">
                  </div>
                  <div></div>
                  <div></div>



                </div>
          </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Utio from "@/components/utio/Utio";
export default {
  name: "MessageS2",
  props:['c1'],
  data(){
    return{
      messageData:{},
      checkedBol:{
        nan:true,
        nv:this.nan,
      }
    }
  },
  created() {
    this.messageData=this.c1.data;




   console.log("hahaha")
  },
  methods:{
    out(){
      this.$emit("out","关闭")
    },
    async update() {
    let {data}=  await axios.get(Utio.ip()+"volunteer.update.id",{
        params:this.messageData,

      })
      if(data==true){
        alert("修改结果成功")

      }else{
        alert("修改结果失败")

      }
    }


  }

}
</script>

<style scoped>

.tt1{

 position: absolute;
  background-color: rgba(7, 0, 0, 0.11);

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tt2{

  background-color: white;
  width: 600px;
  height: 60vh;
  margin-top: 15%;
  margin-left: 40%;


}

.ss3{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 2vh;
}

/*.ss4{*/
/*  display: grid;*/
/*  grid-template-columns: 5fr 5fr ;*/
/*  grid-row-gap: 2vh;*/
/*}*/

</style>