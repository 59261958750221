<template>
<div>

  <div style="" class="hread">
      <div class="ss2" ><img :src="require('@/image/logo.png')" style="margin-left: 10vw"></div>
      <div class="ss2" ><span style="font-size: 5vh">监控颐养平台 后台管理系统</span></div>
      <div class="ss2"><img :src="require('@/image/logo.png')"></div>
      <div class="ss3" ><p style="font-size: 2vh">管理员</p></div>
  </div>
  <div class="ss">


      <div class="b1">
       <div style="margin-top: 20vh">
          <p class="ss6" style="cursor: pointer" @click="indexHome='Index1S2'">志愿者管理员</p>
          <p class="ss6" style="cursor: pointer;margin-top: 10vh;" @click="indexHome='Index2S2'">广告管理</p>



       </div>

      </div>
      <div class="b1">
        <index1-s2 style="width: 100%;height: 100%" v-if="indexHome=='Index1S2'"></index1-s2>
        <index2-s2 style="width: 100%;height: 100%" v-if="indexHome=='Index2S2'"></index2-s2>
      </div>
  </div>





</div>
</template>

<script>
import Index1S2 from "@/components/Index1S2";
import Index2S2 from "@/components/Index2S2";
export default {
  name: "HomeS2",
  components: {Index1S2,Index2S2},
  data(){
    return{
      // indexHome:"Index1S2"
      indexHome:"Index2S2"
    }


  }

}
</script>

<style scoped>
.ss6:hover{
  background-color: red;
}

.ss{
  display: grid;
  grid-template-columns: 1fr 5fr;
  /*grid-column-gap: 1vw;*/
  text-align: center;
}

.ss2{
  display: flex;
  background-color: rgba(0, 247, 255, 0.08);
  height: 10vh;
}

.ss3{
  background-color: rgba(0, 234, 255, 0.08);
  height: 10vh;
}

.hread{
  display: grid;
  grid-template-columns:3fr 6fr 1fr 1fr ;
  grid-column-gap: 1vw;
  height: 10vh

}

.b1{
  border: 1px solid #000000;
  height: 89vh;
  background-color: rgba(0, 208, 255, 0.08);
}



</style>