<template>
<div>
  <div><button style="height: 50px;width: 100px;" @click="insertData()">新增</button></div>
<div  class="table1" >
  <table>
    <thead>
    <th>序号</th>
    <th>广告标题</th>
    <th>广告图片</th>
    <th>状态</th>
    <th>操作</th>
    </thead>
    <tbody>

    <tr v-for="(item,index) of data" :key="index" >
      <td>{{item.id}}</td>
      <td>{{item.title}}</td>
      <td><img :src="item.picture" style="height: 10vh;width: 15vw"></td>
      <td>{{item.status}}</td>
      <td class="ss5">
        <span class="aa" @click="updateData(item)">编译</span>
        <span class="aa" @click="statusOff(item)">{{item.status}}</span>
        <span class="aa" @click="deleteData(item)">删除</span>


      </td>

    </tr>

    </tbody>
  </table>

</div>
<message-index2-s2 :c1="c1" @out="out" v-if="messageShow"></message-index2-s2>
</div>
</template>

<script>
import axios from "axios";
import MessageIndex2S2 from "@/components/MessageIndex2S2";
import Utio from "@/components/utio/Utio";
export default {
  name: "Index2S2",
  components: {MessageIndex2S2},
  data(){
    return{
      data:[],
      c1:{
        start:"",
        data:{}
      },
      messageShow:false,
    }
  },

  async created() {

   let {data}= await axios.get(Utio.ip()+"addvertusement.from", {})
    this.data=data


  },
  methods:{
    async out() {
      this.messageShow = false;

      //刷新
      let cc1 = await axios.get(Utio.ip()+"addvertusement.from", {})
      console.log(cc1)
      this.data = cc1.data
    },
    insertData(){
      this.c1.start="insert"

      this.c1.data={}
      this.messageShow=true;
    },


    updateData(item){
      this.c1.start="update"
      this.c1.data=item

      this.messageShow=true;
    },

    async statusOff(item) {
      item.status=="启用"?item.status="停用":item.status="启用"
      await axios.get(Utio.ip()+"addvertusement.update.id", {
      params:item
      })

    },

    async deleteData(item) {
      if(item.status=="启用"){
        alert("请先停用")
        return;
      }
      let {data}= await axios.get(Utio.ip()+"addvertusement.delete", {
        params:item
      })


      if(data==true){
        let cc1= await axios.get(Utio.ip()+"addvertusement.from", {})
        console.log(cc1)
        this.data=cc1.data
      }



    },


  }

}
</script>

<style scoped>
.ss5{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;

}

th,td{
  padding: 40px; /* 设置单元格内边距 */
  border:1px #070000 ridge;

}

.table1{
  margin-top: 20px;
  background-color: white;
  height: 62vh;
  overflow: auto;

}

.aa{
  cursor: pointer;
}
.aa:hover{
  color: red;
}




</style>