<template>
  <LoginS2></LoginS2>
<!--  <HomeS2></HomeS2>-->

</template>
<script>





import LoginS2 from "@/components/LoginS2";
//
// import HomeS2 from "@/components/HomeS2";
export default {
  name: 'App',
  components: {
    // HomeS2
    LoginS2
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin:0;
  padding:0;
}
</style>
