<template>
<div>
    <div class="tt1">
          <div class="tt2">
                <div  style="margin-top: 10px" class="ss34">
                  <div>广告标题:</div>
                  <div><input type="text" v-model="messageData.title"></div>

                  <div >广告图片:</div>
                  <div>
                    <input type="file" @change="onFileChange" >
                    <img :src="messageData.picture" style="width: 300px;height: 200px;border: black 1px ridge">

                     <p > 上传进度：{{uploadPercentage}}</p>


                  </div>



                  <div>
                    <input type="button" value="添加" v-if="c1.start=='insert'" @click="insert()">
                    <input type="button" value="修改" v-if="c1.start=='update'" @click="update()">
                    <input style="margin-left: 20px" type="button" value="退出" @click="out">
                  </div>




                </div>
          </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Utio from "@/components/utio/Utio";
export default {
  name: "MessageIndex2S2",
  props:['c1'],
  data(){
    return{
      messageData:{},
      file: null,
      uploadPercentage: null
    }
  },
  created() {
    if(this.c1.data!=null){
      this.messageData=this.c1.data;
    }
  },
  methods:{
    //上传图片
   async onFileChange(e){
      this.file = e.target.files[0];

     if (this.file.size > 20 * 1024 * 1024) { // 20MB 的限制
       alert("文件大小不能超过20MB");
       return;
     }
     if (!this.file.type.startsWith('image/')) { //如果不为图片
       alert("请上传图片文件");
       return;
     }


      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('bucketName', 'my-bucketname');
      formData.append('userName', 'zhangsan');

        let {data}=await  axios.post('http://file.00000.work:11001/test/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
            this.uploadPercentage = `${Math.round((100 * progressEvent.loaded) / progressEvent.total)}%`;
          }
          })



       console.log(data.data.url)
     this.messageData.picture=data.data.url;

    console.log(data)


    },
    out(){
      this.$emit("out","关闭")
    },
    async insert() {
      this.messageData.status="启用"
      this.messageData.launch_page="我是广告内容内容"


      let {data} = await axios.get(Utio.ip()+"addvertusement.insert", {
        params: this.messageData,

      })
      console.log("添加结果"+data)
      this.out()

    },


    async update() {


    let {data}=  await axios.get(Utio.ip()+"addvertusement.update",{
        params:this.messageData,

      })

      console.log("修改结果"+data)
      this.out();
    }


  }

}
</script>

<style scoped>

.tt1{

 position: absolute;
  background-color: rgba(7, 0, 0, 0.11);

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tt2{

  background-color: white;
  width: 600px;
  height: 60vh;
  margin-top: 15%;
  margin-left: 40%;


}


.ss34{
  display: grid;
  grid-template-columns: 1fr  ;
  grid-row-gap: 4vh;
}

</style>