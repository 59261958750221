<template>
<div>
<!--  <div>-->
<!--    <input type="file" ref="fileInput" style="display: none" @change="onFileChange">-->
<!--    <img :src="require('@/components/image/c1.png')" @click="triggerFileInput">-->
<!--  </div>-->

  <div class="container" v-if="loginBol">
    <div/>
    <div/>
    <div/>

    <div />
    <div style="">
      <p style="font-size: 5vh">监控颐养平台 后台管理系统</p>
      <br>
      <input type="text" placeholder="账号" v-model="user.name" style="margin-left: -2vw">
      <br>
      <br>
      <input :type="xyj.bol==true?'text':'password'" placeholder="密码" v-model="user.password">
      <span style="margin-left: 10px">
          <img style="width: 20px;height: 20px" :src="xyj.bol==true?xyj.image[0]:xyj.image[1]" @click="xyj.bol=!xyj.bol"/>
    </span>
      <br>
      <br>
      <input type="checkbox" ref="c1"><span>记住密码</span>

      <br>
      <p style="color: red">{{message}}</p>
      <br>
      <input type="button" value="登入" @click="login">

    </div>
    <div/>

  </div>

  <div v-else>
    <HomeS2 ></HomeS2>

  </div>
</div>


</template>

<script>
import Utio from "@/components/utio/Utio";
import axios from "axios";
import HomeS2 from "@/components/HomeS2";
export default {
  cc1:require('@/image/c1.png'),
  name: "LoginS2",
  components: {HomeS2},
  data(){
    return{
      loginBol:true,
      message:"",
      xyj:{
        bol:false, //默认不显示

        image:[
            require("@/image/c1.png"),
            require("@/image/c2.png")
        ]
      },

      user:{
        name:localStorage.getItem("name")||"",
        password:localStorage.getItem("password")||"",
      },
    }
  },
  methods:{

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0];
      if (this.validateImageFile(selectedFile)) {
        this.uploadFile(selectedFile);
      } else {
        alert('请选择图片格式的文件');
      }
    },

    async login() {
      if(this.user.name.trim()===""){
        this.message="请输入账号"
        return;
      }
      if(this.user.password.trim()===""){
        this.message="请输入密码"
        return;
      }

      if (this.$refs.c1.checked) {
        localStorage.setItem("name", this.user.name)
        localStorage.setItem("password", this.user.password)
      } else {
        localStorage.setItem("name", "")
        localStorage.setItem("password", "")
      }


      //  进行登入
     let {data}= await axios.get(Utio.ip()+"from_login", {
        params:{
          username:this.user.name,
          password:this.user.password,
        }
      })
      if(data===""){ //如果登入失败
        this.message="账号或者密码错误"
        return;
      }


      this.loginBol=false; //登入成功关闭登入界面




    },
    validateImageFile(file) {
      return file.type.startsWith('image/');
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);

      axios.post('http://file.00000.work:11001/test/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        // onUploadProgress: progressEvent => {
        //   // 处理上传进度
        // }
      })
          .then(response => {
            console.log('文件上传成功', response.data);
          })
          .catch(error => {
            console.error('文件上传失败', error);
          });
    },


  },

   // created() {
   //  // console.log(this.user.password)
   //  // if(this.user.password!=""){
   //  //   this.$refs.c1.checked=true;
   //  // }
   //  //
   //  },


}

</script>

<style scoped>
.container {
  background-color: rgba(0, 166, 255, 0.06);
 display: grid;
  /*grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));*/
  grid-template-columns: 1fr 2fr 1fr ;
  grid-column-gap: 10vw; /* 设置列之间的间距为10px */
  grid-row-gap: 10vh; /* 设置行之间的间距为10px */
}
 .box1{
  /*grid-row: 1/3;*/
  /*grid-column: 1/2;*/
}

</style>